import { lazyLoadComponent } from '@/base-module/router/base-router'
import { RouteConfig } from 'vue-router'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { GipodImpactOv } from '@/infrastructure/constants/scopes'

export const DeLijnRoute: RouteConfig = {
  path: '/de-lijn',
  name: 'de-lijn',
  component: lazyLoadComponent(() => import('./de-lijn.vue')),
  meta: {
    authorize: {
      scopes: [GipodImpactOv],
    },
    title: 'De Lijn',
    activeNavigation: GipodNavigationItemKey.DeLijn,
  },
}

export default [DeLijnRoute] as RouteConfig[]
