import Vue from 'vue'
import VlUiVueComponents, { VlModalToggle, VlTooltip } from '@govflanders/vl-ui-vue-components'

export default {
  install(vue: typeof Vue): void {
    vue.use(VlUiVueComponents)
    vue.directive('vl-tooltip', VlTooltip)
    vue.directive('vl-modal-toggle', VlModalToggle)
  },
}
