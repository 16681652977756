import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { GipodMhRead, GipodMhWrite } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteToelatingen } from '../toelatingen-routes'

export const RouteRequestForGroundworkDetail: GipodRouter.GRouteConfig = {
  path: `${RouteToelatingen.path}/vraagvoorgrondwerken/:gipodId`,
  name: 'vraag-voor-grondwerken-detail',
  component: lazyLoadComponent(() => import('./vraagvoorgrondwerken-detail.vue')),
  meta: {
    authorize: {
      scopes: [GipodMhRead, GipodMhWrite],
    },
    title: `Vraag voor grondwerk | ${RouteToelatingen.meta.title}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteRequestForGroundworkDetail] as GipodRouter.GRouteConfig[]
