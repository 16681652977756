import { GipodRouter } from '../gipod-router'
import { NavigationGuardNext } from 'vue-router'

export async function setTitleGuard(
  to: GipodRouter.GRoute,
  _: GipodRouter.GRoute,
  next: NavigationGuardNext,
) {
  if (document && to.meta) {
    if (to.meta.title) {
      document.title = to.meta.title
    } else if (to.meta.dynamicTitle) {
      document.title = to.meta.dynamicTitle(to)
    }
  }
  next()
}
