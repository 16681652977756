const sessionStorageTransfer = (event: StorageEvent) => {
  if (!event.newValue) return
  if (event.key === 'getSessionStorage') {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem('sessionStorage')
  } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
    // another tab sent data <- get it
    const data = JSON.parse(event.newValue)
    for (const key in data) {
      sessionStorage.setItem(key, data[key])
    }
  }
}

export const initialiseSynchronizedSession = () => {
  window.addEventListener('storage', sessionStorageTransfer, false)

  localStorage.setItem('getSessionStorage', '_')
  localStorage.removeItem('getSessionStorage')
}
