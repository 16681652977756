import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
// import { GipodMhRead, GipodMhWrite } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteToelatingen } from '../../../toelatingen-routes'

export const RouteJaarvergunningSignalisatieDetail: GipodRouter.GRouteConfig = {
  path: `${RouteToelatingen.path}/jaarvergunning-signalisatie/:gipodId`,
  name: 'jaarvergunningen-detail',
  component: lazyLoadComponent(() => import('./jaarvergunningen-detail.vue')),
  meta: {
    authorize: {
      scopes: [],
    },
    title: `Jaarvergunning Signalisatie | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteJaarvergunningSignalisatieDetail] as GipodRouter.GRouteConfig[]
