import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodMhRead } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteConfig } from 'vue-router'

export const RouteHinder: GipodRouter.GRouteConfig = {
  path: '/hinder',
  name: 'hinder',
  component: lazyLoadComponent(() => import('./hinder.vue')),
  meta: {
    authorize: {
      scopes: [GipodMhRead],
    },
    title: `Hinder | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Hindrance,
  },
}

export default [RouteHinder] as RouteConfig[]
