import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { RouteIntakes } from '@/inname-module/inname-routes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteConfig } from 'vue-router'

export const RouteSignalisatievergunningLees: GipodRouter.GRouteConfig = {
  path: '/toelatingen/signalisatievergunning/:id',
  name: 'signalisatievergunninglees',
  component: lazyLoadComponent(() => import('./signalisatievergunning-lees.vue')),
  meta: {
    authorize: {
      scopes: [],
    },
    redirect: () => `${RouteIntakes.alias}`,
    title: `Signalisatievergunning | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteSignalisatievergunningLees] as RouteConfig[]
