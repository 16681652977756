import _Vue from 'vue'
import { upperFirst, camelCase } from 'lodash'
import ModuleLoader from '@/plugins/helpers/module-loader'

const isMatch =(path: string)  => path.match(/([^/]+)(?=\.\w+$)/);
const loader = new ModuleLoader('global-components', import.meta.glob('@/**/*.vue'));
export const componentLoaderTask = loader;

export default {
  install(vue: typeof _Vue) {
    loader.iter<any[]>((path, modulePromises) => { 
      const regex = isMatch(path)
      if (!(regex && regex.length > 0)) {
        return;
      };
      const name = upperFirst(camelCase(regex[1]));
      vue.component(name, modulePromises)
    });
  },
}