import { GipodRouter } from '../gipod-router'
import { NavigationGuardNext } from 'vue-router'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'

export async function appsettingsGuard(
  to: GipodRouter.GRoute,
  _: GipodRouter.GRoute,
  next: NavigationGuardNext,
) {
  if (ApplicationSettings.isEmpty) {
    await ApplicationSettings.updateApplicationSettings()
  }
  return next()
}
