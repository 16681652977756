import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { ApiProxySingleton } from '@/plugins/proxy-client';
import { ApplicationSettings as ApplicationSettingsType } from '@/api/api.proxy';
import store from '../base-store';

enum FEATURE_FLAGS {
    GroundworkNotificationsList = 'GroundworkNotificationsList',
    HistoryOnHinder = 'HistoryOnHinder',
    HistoryOnSynergie = 'HistoryOnSynergie',
    HistoryOnSynergieAanvraag = 'HistoryOnSynergieAanvraag',
    GeneralPermitForGroundworks = 'GeneralPermitForGroundworks',
    AnnualPermit = 'AnnualPermit'
}

@Module({
    dynamic: true,
    store,
    name: 'ApplicationSettingsModule',
    namespaced: true
})
export class ApplicationSettingsModule extends VuexModule {
    isEmpty: boolean = true;
    applicationSettings: ApplicationSettingsType = null;
    pending: boolean = false;

    FEATURE_FLAGS = FEATURE_FLAGS;

    get featureFlags() {
        if (!this.applicationSettings) return {};
        return this.applicationSettings.featureFlags;
    }

    get featureFlag() {
        const featureFlags = this.context.getters.featureFlags || {};
        return (name: FEATURE_FLAGS) => {
            return featureFlags && featureFlags[name];
        };
    }

    get notificationsRefreshTimer() {
        return this.applicationSettings.notificationRefreshTimer;
    }

    @Mutation
    setPending() {
        this.pending = true;
    }

    @Mutation
    setApplicationSettings(applicationSettings: ApplicationSettingsType) {
        this.applicationSettings = applicationSettings;
        this.pending = false;
        this.isEmpty = false;
    }

    @Action
    async updateApplicationSettings() {
        this.setPending();
        try {
            const settings = await ApiProxySingleton.utilities_GetApplicationSettings();
            this.setApplicationSettings(settings);
        } catch (e) {}
    }
}

export const ApplicationSettings = getModule(ApplicationSettingsModule);
