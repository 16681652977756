import Vue from 'vue'
import { User } from '@/base-module/state/UserModule'
import { GipodPdoRead, GipodPdoWrite } from '@/infrastructure/constants/scopes'

export default {
  install(vue: typeof Vue): void {
    vue.prototype.$scopeCheck = ScopeCheck
  },
}

export class ScopeCheck {
  static hasGipodRead() {
    return User.hasScope([GipodPdoRead])
  }
  static hasGipodWrite() {
    return User.hasScope([GipodPdoWrite])
  }
  static hasGipodReadOrWrite() {
    return User.hasScope([GipodPdoWrite, GipodPdoRead])
  }
}
