import Vue from 'vue'
import app from '@/base-module/app.vue'
import { routerFactory } from '@/base-module/router/base-router'
import routes, { routeLoaderTask } from '@/plugins/routes/routes'
import proxyClient from '@/plugins/proxy-client'
import store from '@/base-module/base-store'
import ipproComponents from '@/plugins/ippro-components'
import globalComponents, { componentLoaderTask } from '@/plugins/gipod-component'
import GipodLog, { Logger } from '@/plugins/logger'
import uuid from '@/plugins/uuid'
import directives, { directiveLoaderTask } from '@/plugins/gipod-directives'
import webComponents from '@/plugins/web-components'
import Vuelidate from 'vuelidate'
// import VueCompositionApi from '@vue/composition-api';
// Vue.use(VueCompositionApi);

import VueRouter from 'vue-router'
import authHelper from '@/plugins/auth-helper'
import { initialiseSynchronizedSession } from '@/infrastructure/helpers/localStorage'

const createVueApp = async () => {
  initialiseSynchronizedSession()

  Vue.use(VueRouter)

  const router = routerFactory()

  Vue.use(Vuelidate)
     .use(authHelper)
     .use(routes, { router })
     .use(proxyClient)
     .use(GipodLog, new Logger())
     .use(globalComponents)
     .use(directives)
     .use(uuid)
     .use(ipproComponents)
     .use(webComponents)
  Vue.config.productionTip = false

  if (import.meta.env.NODE_ENV === 'development') {
    require('./plugins/git').default()
  }

  /* Vue2 doesn't support async plugins */
  await directiveLoaderTask.wait(),
  await componentLoaderTask.wait();
  await routeLoaderTask.wait();

  return new Vue({
    router,
    store,
    render: h => h(app),
  }).$mount('#app')
}

export default createVueApp()
