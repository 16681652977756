import { lazyLoadComponent } from '@/base-module/router/base-router'
import {
  GipodMhRead,
  GipodPdoRead,
  GipodTsRead,
} from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteConfig } from 'vue-router'

export const RouteKaart: RouteConfig = {
  path: '/kaart',
  name: 'kaart',
  component: lazyLoadComponent(() => import('./kaart.vue')),
  meta: {
    title: 'Kaart',
    activeNavigation: GipodNavigationItemKey.Kaart,
    authorize: {
      scopes: [GipodMhRead, GipodPdoRead, GipodTsRead],
    },
  },
}

export default [RouteKaart] as RouteConfig[]
