import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodMhRead } from '@/infrastructure/constants/scopes'
import { RouteIntakes } from '@/inname-module/inname-routes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteConfig } from 'vue-router'

export const RouteToelatingenSignalisatie: GipodRouter.GRouteConfig = {
  path: '/toelatingen-signalisatie',
  name: 'toelatingen-signalisatie',
  component: lazyLoadComponent(() => import('./toelatingen-signalisatie.vue')),
  meta: {
    authorize: {
      scopes: [GipodMhRead],
    },
    redirect: (route: GipodRouter.GRoute) => `${RouteIntakes.alias}`,
    title: `Toelatingen Signalisatie | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteToelatingenSignalisatie] as RouteConfig[]
