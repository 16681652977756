import { lazyLoadComponent } from '@/base-module/router/base-router';
import { GipodRouter } from '@/base-module/router/gipod-router';
import { BaseTitle } from '@/infrastructure/constants/route-titles';
import { GipodSpRead } from '@/infrastructure/constants/scopes';
import { RouteIntakes } from '@/inname-module/inname-routes';
import { GipodNavigationItemKey } from '@/plugins/routes/routes';
import { RouteConfig } from 'vue-router';

export const RouteOverzichtJaarvergunning: GipodRouter.GRouteConfig = {
    path: '/toelatingen/jaarvergunning',
    name: 'jaarvergunning',
    component: lazyLoadComponent(() => import('./jaarvergunning-contractor-view.vue')),
    meta: {
        authorize: {
            scopes: [GipodSpRead]
        },
        redirect: (route: GipodRouter.GRoute) => `${RouteIntakes.alias}`,
        title: `Overzicht Jaarvergunningen | ${BaseTitle}`,
        activeNavigation: GipodNavigationItemKey.Jaarvergunningen
    }
};

export default [RouteOverzichtJaarvergunning] as RouteConfig[];
