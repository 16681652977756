import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { GipodTsRead } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteSamenwerkingen } from '../samenwerkingen-module-routes'

export const RouteSynergieAanvraag: GipodRouter.GRouteConfig = {
  path: `${RouteSamenwerkingen.path}/synergieaanvraag/:id`,
  name: 'synergieaanvraag',
  component: lazyLoadComponent(() => import('./synergieaanvraag.vue')),
  meta: {
    authorize: {
      scopes: [GipodTsRead],
    },
    dynamicTitle: route =>
      `SynA ${route.params.id} | ${RouteSamenwerkingen.meta.title}`,
    activeNavigation: GipodNavigationItemKey.Cooperation,
  },
}

export default [RouteSynergieAanvraag] as GipodRouter.GRouteConfig[]
