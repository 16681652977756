import { envStyling } from '@/infrastructure/helpers/ui'
import GipodHeader from '@/base-module/components/gipod-header/gipod-header.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Alert, AlertType, AlertMethod } from '@/base-module/state/Alerts'
import { changeLocation } from '@/infrastructure/helpers/network'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'
import 'reflect-metadata'
import { ApiProxySingleton } from '@/plugins/proxy-client'

@Component({
  name: 'App',
  components: {
    GipodHeader,
  },
  provide:{
    apiProxy: ApiProxySingleton
  }
})
export default class App extends Vue {
  get alerts() {
    return Alert.alertsByPage(this.$route, AlertMethod.Toast)
  }

  get types() {
    return AlertType
  }

  get envStyling() {
    return envStyling()
  }

  get hasLoginIssues() {
    return this.$route.query && this.$route.query.error === 'failed-to-login'
  }

  removeAlert(id: string) {
    Alert.removeAlertsById([id])
  }

  logout() { 
    changeLocation(`${window.location.origin}/auth/logout`)    
  }

  mounted() {
    this.$root.$on('closed', (modalId: string) => {
      this.$root.$emit('gipod-modal-close-' + modalId, false)
    })
  }
}
