import _Vue from 'vue'

export default {
  install(Vue: typeof _Vue): void {
    Vue.component('ippro-icon-text', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-icon-text/ippro-icon-text.vue'))
    Vue.component('ippro-datatable', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-datatable/ippro-datatable.vue'))
    Vue.component('ippro-map', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/vl-ippro-map.vue'))
    Vue.component('ippro-map-search', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-search/ippro-map-search.vue'))
    Vue.component('ippro-map-draw-controls', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-draw-controls/ippro-map-draw-controls.vue'))
    Vue.component('ippro-map-zoom-to-feature', () => import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-zoom-to-feature/ippro-map-zoom-to-feature.vue'))
    Vue.component('ippro-map-popup', ()=> import('@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-popup/ippro-map-popup.vue'))
  },
}