import dayjs from 'dayjs'

export const ObjectDuplicator = (obj: any) => JSON.parse(JSON.stringify(obj))

export interface IFlatArrayElement {
  indentation: number
  parentIsDisabled?: boolean
  [index: string]: any
}

export const nameof = <T>(name: keyof T) => name

export const Flattener = (
  flatArray: IFlatArrayElement[],
  initial: any[],
  indentation: number = 0,
  disableParent: boolean = false,
  accessor: string = 'Children',
) => {
  try {
    if (initial && Array.isArray(initial)) {
      initial.forEach(({ [accessor]: acc, ...type }) => {
        flatArray.push({
          indentation,
          parentIsDisabled: !!acc && acc.length > 0 && disableParent,
          ...type,
        })
        if (acc && acc.length > 0) {
          Flattener(flatArray, acc, indentation + 1, disableParent, accessor)
        }
      })
    }
  } catch (error) {
    /* console.error(error); */
  }
}

export const validDate = (date: string[], removeTime: boolean = false) => {
  if (date && date.length > 0) {
    let dateDayjs = dayjs(date[0])
    if (removeTime) {
      dateDayjs = dateDayjs.startOf('day')
    }
    return dayjs(dateDayjs).toDate()
  } else {
    return null
  }
}

export const gipodString = (val: any) => {
  if (typeof val === 'string' || typeof val === 'boolean') {
    return val
  } else if (dayjs(val).isValid()) {
    return dayjs(val).toISOString()
  } else {
    return JSON.stringify(val)
  }
}
