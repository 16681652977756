import { User, UserModule } from '../../state/UserModule'
import { GipodRouter } from '../gipod-router'
import { changeLocation } from '@/infrastructure/helpers/network'
import { Location, NavigationGuardNext } from 'vue-router'
import { RouteZoneInstellingen } from '@/instellingen-zone-module/instellingen-zone-routes'
import { GipodOrgRead } from '@/infrastructure/constants/scopes'
import { RouteSignalisatievergunningsaanvraag } from '@/toelatingen-module/signalisatievergunning-module/aanvraag-module/aanvraag-routes'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'
import { RouteOverzichtToelatingen } from '@/toelatingen-module/modules/signalisatievergunning/signalisatievergunning-mijn-aanvragen-overzicht/signalisatievergunning-mijn-aanvragen-overzicht-routes'

export async function authenticationGuard(
  to: GipodRouter.GRoute,
  _: GipodRouter.GRoute,
  next: NavigationGuardNext,
) {
  try {
    if (
      to.query &&
      (to.query.error === 'failed-to-login' ||
        to.query.error === 'not-supported')
    ) {
      return next()
    }

    if (to.matched[0].path === '/no-roles') {
      // exclude no-roles page from authguard
      // this page is used to redirect users to when they don't have the correct roles
      await User.updateUserProfile() // update user profile to display its ACM ID
      return next()
    }

    const result = await authenticate(to)
    if (result) return next(result)
    return next()
  } catch (e) {
    redirectToLogin()
  }
}

export async function authenticate(to: GipodRouter.GRoute): Promise<Location> {
  if (
    !(to.meta && to.meta.authorize && Object.keys(to.meta.authorize).length > 0)
  ) {
    return null
  }
  return User.isUserAuthenticated().then(async isAuthenticated => {
    if (!User.isLoggedIn) await User.updateUserProfile()
    if (
      to.meta &&
      to.meta.authorize &&
      Object.keys(to.meta.authorize).length > 0
    ) {
      return checkUserState(to, User, isAuthenticated)
    } else {
      return null
    }
  })
}

export async function checkUserState(
  to: GipodRouter.GRoute,
  userData: UserModule,
  isLoggedIn: boolean,
): Promise<Location> {
  let authorized = true
  // Check if logged in when there's authorization requirements
  if (
    (to.meta?.authorize?.scopes?.length ||
      to.meta?.authorize?.organisations?.length) &&
    !isLoggedIn
  ) {
    return Promise.reject()
  }
  // Check for roles/scopes
  if (userData.userInfo?.scopes?.length < 0) {
    return { path: '/no-roles' }
  }
  if (to.meta?.authorize?.scopes?.length) {
    if (!userData.hasScope(to.meta.authorize.scopes)) {
      authorized = false
    }
  }
  // Check for guids
  if (to.meta?.authorize?.organisations?.length) {
    if (!userData.hasGuids(to.meta.authorize.organisations)) {
      authorized = false
    }
  }

  if (to.meta?.authorize?.localGovernment) {
    if (!userData.isLocalGovernement) authorized = false
  }

  if (to.meta?.authorize?.domainAdmin) {
    if (!userData.isDomainAdmin) authorized = false
  }

  if (!authorized) {
    if (to.meta?.redirect) {
      return { path: to.meta.redirect(to) }
    } else if (
      userData.isContractorOnly 
    ) {
      return ApplicationSettings.featureFlag(ApplicationSettings.FEATURE_FLAGS.AnnualPermit) //Route to signalisatievergunningsaanvraag if contractor and feature flag is disabled
          ? RouteOverzichtToelatingen
          : RouteSignalisatievergunningsaanvraag;
    } else if (userData.hasScope([GipodOrgRead])) {
      return RouteZoneInstellingen
    } else {
      return {
        path: '/',
        query: { error: 'failed-to-login' },
      }
    }
  }
  return null
}

export function redirectToLogin() {
  if (process.env.VITEST) {
    return;
  } 

  

  const { origin, pathname, search } = window.location;
  const state = encodeURIComponent(pathname + search);
  const method =  "auth" 
  const newLocation = `${origin}/${method}/login?state=${state}`;

  return changeLocation(newLocation)
}
