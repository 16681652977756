import { lazyLoadComponent } from '@/base-module/router/base-router'
import { RouteConfig } from 'vue-router'

export const Dashboard: RouteConfig = {
  path: '/dashboard',
  name: 'dashboard',
  component: lazyLoadComponent(() => import('./dashboard.vue')),
}

export default [Dashboard] as RouteConfig[]
