import { Component, Watch, Vue } from 'vue-property-decorator'
import FunctionalHeader from '@/base-module/components/functional-header/functional-header.vue'
import axios from 'axios'
import { envStyling } from '@/infrastructure/helpers/ui'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'
import * as widgetClient from '@govflanders/vl-widget-client'
import { Widget } from '@govflanders/vl-widget-core/types/plugin'
import { User, UserModule } from '../../state/UserModule'
import { Getter } from 'vuex-class'

@Component({
  name: 'GipodHeader',
  components: {
    FunctionalHeader,
  },
})
export default class GipodHeader extends Vue {
  @Getter('authenticated', { namespace: UserModule.namespace })
  public authenticated!: number

  get envStyling() {
    return envStyling()
  }

  get globalHeaderWidgetId() {
    return import.meta.env.VITE_GLOBAL_HEADER_WIDGET_ID
  }

  get acmHeaderId() {
    return import.meta.env.VITE_GLOBAL_HEADER_ACM_WIDGET_ID
  }

  get acmHeaderUrl() {
    return import.meta.env.VITE_GLOBAL_HEADER_ACM_WIDGET_URL
  }

  private generateHeader() {
    const websiteHasAuthenticatedSession = false
    
      widgetClient
        .bootstrap(`${this.acmHeaderUrl}/api/v1/widget/${this.acmHeaderId}`)
        .then((widget: Widget) => {
          widget.setMountElement(
            document.getElementsByClassName('global-header')[0],
          )
          widget.mount()
          widget
            .getExtension('citizen_profile.session')
            .then((session: any) => {
              session.configure({
                active: websiteHasAuthenticatedSession,
                endpoints: {
                  loginUrl: `/auth/login`,
                  loginRedirectUrl: `/`,
                  switchCapacityUrl: `/auth/switch`,
                  logoutUrl: `/auth/logout`,
                },
              })
            })
        })
   
  }

  async mounted() {
    await ApplicationSettings.updateApplicationSettings()

    this.generateHeader()
  }

  @Watch('authenticated', { immediate: true, deep: true })
  public onAuthenticatedChanged() {
    if (!this.authenticated) {
      return
    }
    this.widgetCapture()
    this.widgetActivity()
  }

  private widgetCapture() {
    widgetClient.capture((widget: Widget) => {
      const userActive = User.isLoggedIn
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      widget.getExtension('citizen_profile.session').then((session: any) => {
        session.configure({ active: userActive })
      })
    })
  }

  private widgetActivity() {
    widgetClient.capture((widget: Widget) => {
      if (widget.getPluginTypeId() === 'global_header') {
        // Get the Citizen Profile Session extension from the global header widget.
        widget.getExtension('citizen_profile.session').then((session: any) => {
          /**
           * Event handler which extends a Citizen Profile session.
           */
          function activityEventHandler() {
            // Inform the Citizen Profile Session extension about activity.
            session.extend()
          }

          // Build a list of event names which should be used for activity tracking.
          const eventNames = [
            'mousedown',
            'mousemove',
            'mousewheel',
            'DOMMouseScroll',
            'scroll',
            'wheel',
            'keydown',
            'keypress',
            'touchmove',
            'touchstart',
          ]
          // Iterate through the events names.
          for (const eventName of eventNames) {
            // Register our event handler given event name.
            window.addEventListener(eventName, activityEventHandler)
          }
        })
      }
    })
  }
}
