export const GipodPdoRead = 'gipod_pdo_read'
export const GipodPdoWrite = 'gipod_pdo_write'
export const GipodTsRead = 'gipod_ts_read'
export const GipodTsWrite = 'gipod_ts_write'
export const GipodOrgSettings = 'gipod_org_settings'
export const GipodOrgRead = 'gipod_org_read'
export const GipodOrgWrite = 'gipod_org_write'
export const GipodMhRead = 'gipod_mh_read'
export const GipodMhWrite = 'gipod_mh_write'
export const GipodImpactOv = 'gipod_impact_ov'
export const GipodNotifications = 'gipod_notifications'
export const GipodSpWrite = 'gipod_sp_write'
export const GipodSpRead = 'gipod_sp_read'
