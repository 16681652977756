import Vue from 'vue'
import { LogEntry, IApiProxy, ApiProxy } from '@/api/api.proxy'

export default {
  install(vue: typeof Vue, logger: Logger): void {
    vue.prototype.$log = logger
  },
}

export class Logger {
  apiProxy: IApiProxy

  constructor(apiProxy: IApiProxy = null) {
    this.apiProxy = apiProxy || new ApiProxy(import.meta.env.VITE_BASE_URI)
  }

  send(message: LogEntry) {
    this.apiProxy.utilities_AddLog(message)
  }
}
