import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodSpRead } from '@/infrastructure/constants/scopes'
import { RouteIntakes } from '@/inname-module/inname-routes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { defineAsyncComponent } from 'vue'
import { RouteConfig } from 'vue-router'

export const RouteOverzichtToelatingen: GipodRouter.GRouteConfig = {
  path: '/toelatingen/signalisatievergunning',
  name: 'signalisatievergunning',
  component:defineAsyncComponent({
    loader: () => import('./signalisatievergunnig-mijn-aanvragen-overzicht.vue'),
    timeout: 3000,
  }), 
  meta: {
    authorize: {
      scopes: [GipodSpRead],
    },
    redirect: (route: GipodRouter.GRoute) => `${RouteIntakes.alias}`,
    title: `Overzicht signalisatievergunningen | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Signalisatievergunningen,
  },
}

export default [RouteOverzichtToelatingen] as RouteConfig[]
