import { Component, Vue, Watch } from 'vue-property-decorator'

import { MeldingenRoute } from '@/meldingen-module/meldingen-routes'
import { User } from '@/base-module/state/UserModule'
import { RouteConfig } from 'vue-router'
import md5 from 'md5'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'

@Component({
  name: 'NotificationIcon',
})
export default class NotificationIcon extends Vue {
  notificationCount: number = 0
  lastVisitDateTime: Date = null

  get meldingenRoute() {
    return MeldingenRoute
  }

  get selectedOrganisation() {
    return User.selectedOrganisation
  }

  get localStorageKey() {
    return `notification-visit-${md5(this.selectedOrganisation)}`
  }

  @Watch('selectedOrganisation', { immediate: true })
  onSelectedOrganisationChange(newOrganisation: string) {
    if (!!newOrganisation) {
      this.checkNavigationCount()
    }
  }

  @Watch('$route', { immediate: true })
  onRouteChange(newRoute: RouteConfig) {
    if (!!newRoute && newRoute.name === MeldingenRoute.name) {
      localStorage.setItem(this.localStorageKey, Date.now().toString())
      this.checkNavigationCount()
    }
  }

  checkNavigationCount() {
    if (!!this.selectedOrganisation) {
      const lastVisit = localStorage.getItem(this.localStorageKey)
      if (lastVisit) {
        try {
          this.lastVisitDateTime = new Date(parseInt(lastVisit))
        } catch (err) {}
      }
      this.$client
        .notificationRead_Count(
          User.selectedOrganisation,
          this.lastVisitDateTime,
        )
        .then(count => {
          this.notificationCount = count.numberOfNotifications
        })
        .catch(err => {})
    }
  }

  created() {
    this.checkNavigationCount()
    setInterval(
      this.checkNavigationCount,
      ApplicationSettings.notificationsRefreshTimer,
    )
  }
}
