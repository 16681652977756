export const entityTypeGroundwork = '718225ee-78e8-4b6f-bc63-7748cfd0a4d2';
export const entityTypeWork = '60ad4826-893d-4695-b815-a0adb39287e4';
export const entityTypeEvent = '0aee5e89-610b-4572-9a81-c3de1d413de2';
export const entityTypeGeneralPermit = '8f72677e-71b0-4dda-8bb0-5b1ebef2af16';
export const entityTypeSleufsynergie = '2023553f-ce11-4f76-ba1d-2526cf5e8065';
export const entityTypeSleufsynergieAanvraag = 'd88358b6-a969-4c58-a5fc-9aad8cf0808e';
export const entityTypeSignalisatieVergunning = 'bb5747d8-210b-4f98-853c-5a363c9ef203';
export const entityTypeMobiliteitshinder = '079e1ab0-cc93-4e7c-9c7c-aefb6b2c6ff2';
export const entityTypeVraagVoorGrondwerk = '8415405b-2a06-4d8c-a7a7-bba2a47bda94';
export const entityTypeVraagVoorAlgemeneToelating = '8F72677E-71B0-4DDA-8BB0-5B1EBEF2AF16';
export const entityTypeJaarlijkseSignalisatieVergunning = '9f72677e-71b0-4dda-8bb0-5b1ebef2af17';
export const groundworkCategoryOne = 'bb0b471a-7f4d-4eaa-b788-adae1aaf30f8';
export const groundworkCategoryTwo = '1cf4ebfb-8702-4fc3-a1b1-9b22c3b909f9';
export const groundworkCategoryThree = '2acf7c23-ce34-4655-974f-466cbcfa4f11';
export const groundworkCategoryUrgent = 'eb21d621-8f96-4bb9-9abd-7a8abdeade39';
export const intakeStatusExecuted = '3603c0b5-778e-4407-ae57-7300e0694888';
export const intakeStatusExecuted2 = '99420938-6ee2-46fe-92af-f626742dcbd2';
export const intakeStatusEnded = '4fe0efbe-710d-4e24-8806-d0c9134264a2';
export const intakeStatusNotExecuted = '1ec13adc-3d6b-42be-8298-4d609257617c';
export const intakeStatusNotExecuted2 = '2a078dcd-aa8c-4776-bcd5-7fc2e63e4f8a';
export const intakePaused = 'cded0089-13e1-4416-8742-67ff69d4b653';
export const intakeStatusVoorontwerp = '3232baa5-a157-4126-a2be-354ad1e480f7';
export const grbStatusNo = '25750cda-438f-40b5-bf43-10668bd9b40b';
export const grbStatusWithAsBuiltPlan = '4ab620a1-0459-4d52-9804-560f8637913b';
export const grbStatusWithoutAsBuiltPlan = 'dbd92265-bc64-4f07-91e6-5752b52ea17a';
export const aannemer = 'a55eb0a5-84fb-49f8-83c5-de669e4ae48e';
export const signalisatieverantwoordelijke = 'ae18c328-a48f-461d-bb7d-e666ac359542';
export const dossierbeheerder = 'aad5e947-f7bf-4592-a84a-f591555b0b8b';
export const vgwverantwoordelijke = '70e9e5e8-1c8d-4c78-8c02-69cb2e4e2f09';
export const ssaStatusIngetrokken = '2d155da3-1ce8-454c-96e5-b583657521e3';
export const ssaStatusNew = '7d3df28a-d436-4f26-86b1-b51412bd4ce8';
export const ssaStatusInProgress = 'eb267d0d-a688-44eb-95de-f13113a422e4';
export const ssaStatusAfgeslotenZonderGevolg = '57123bf3-2bcf-40d5-a20b-2c62735b526d';
export const ssaStatusAfgeslotenMetGevolg = '4e4a946c-6d30-46df-bc52-d1e8cb0f5726';
export const synStatusConcreetGepland = '8c9f61ab-af83-4498-868f-4d9cd3774e56';
export const synStatusUitgevoerd = '1c5a85ee-a319-4f07-a0a5-b46548cc12d4';

export const hinderStatusOnbekend = 'a411c53e-db33-436a-9bb9-d62d535b661d';
export const hinderStatusInOpmaak = '0a4ee99b-8b8a-47c8-913f-117220febee0';
export const hinderStatusGevalideerd = '5cc9ee5b-fa97-4d71-8851-51a9d0e2ebd0';

export const notificationCategoryInformatie = '28c2f2d3-b2e8-4eae-8fad-7f820b3d8ec3';
export const notificationStatusNieuw = '00571545-1e66-4e5c-afad-19b898ba14a4';
export const notificationStatusAfgehandeld = 'ff350995-1604-45a7-bbd5-e4b4f4db4932';
export const deLijnGuid = 'b52e8989-fda9-0b8d-a046-fa38cb02069c';
export const aannemerGuid = 'a55eb0a5-84fb-49f8-83c5-de669e4ae48e';
export const svaStatusInAanvraag = '58ec7284-de2d-42dd-a00f-54dccb523f1c';
export const svaStatusVergund = '4ad33275-3f64-4811-b834-3475f8ceba40';
export const svaStatusGeweigerd = 'a0b3ece8-1b41-4c45-8a8f-09203ff886fc';
export const svaStatusIngetrokken = '62fce9c9-67d3-4698-9d81-f425fe7ea9b3';

export const vvgStatusAkkoord = '1e2c26bb-d1fb-4ff1-b26c-eafce4e3e44e';
export const vvgStatusGeenAkkoord = '1931e69d-62c8-4213-9cc4-417bfefa97db';
export const vvgStatusInBehandeling = 'a898fc14-049e-4d0b-bddf-8105dae90d94';
export const vvgStatusNietBevoegd = 'b2ae4edf-8266-4bac-8cb5-f8d22a3a4847';
export const vvgStatusNieuw = '016c6112-2eb0-4d52-93b6-e7b74cc05511';
export const vvgStatusTermijnVerstreken = '29c75c07-cb4f-4501-a33d-a0b33831574f';
export const vvgStatusIngetrokken = 'b3acf864-53fe-44c0-9991-387d94982770';

export const atStatusInAanvraag = 'e4e79e8e-317b-4c9a-b9c3-d26a9c4669b8';
export const atStatusGoedgekeurd = '54009cfb-8161-4356-aa1c-dff7113f048f';
export const atStatusGeweigerd = 'b9363218-427a-48c1-9888-91c2ae65979a';
export const atStatusIngetrokken = 'ec2b770f-ed70-4d4a-a8ce-906566ce0ee0';

export const permitValidityValidFullTerritory = 'f5c03ecb-eaa9-471e-b31b-d9ae46f69817';
export const permitValidityValidPartialTerritory = 'b5ccb429-567d-465d-acab-9ccc307081fa';
export const permitValidityInvalid = 'a427e4ce-45ed-4acd-9ccd-dff0b2054b77';

export const changeRequestTypeRemove = '51209ad3-37e3-4c85-8b85-a55ea0837e99';
export const changeRequestTypeAdd = '4b2f5d9f-2c27-44d1-9b1c-5d51762da451';
export const contextTagVgw = '17709a38-2019-4172-b77f-dfbc3509928e';

export const contactRoleDossierBeheerder = 'aad5e947-f7bf-4592-a84a-f591555b0b8b';

export const jvsStatusInAanvraag  = '58ec7284-de2d-42dd-a00f-54dccb523f1c';
export const jvsStatusGoedgekeurd ='4ad33275-3f64-4811-b834-3475f8ceba40';
export const jvsStatusGeweigerd   =  'a0b3ece8-1b41-4c45-8a8f-09203ff886fc';
export const jvsStatusIngetrokken ='62fce9c9-67d3-4698-9d81-f425fe7ea9b3';
