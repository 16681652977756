import store from '@/base-module/base-store'
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators'
import { v4 as uuid } from 'uuid'

export enum AlertIcons {
  InfoCircle = 'info-circle',
  AlertCircle = 'alert-circle',
  Warning = 'warning',
}

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Error = 'Error',
}

export enum AlertMethod {
  Toast = 'toast',
  Page = 'inpage',
}

export interface IAlertPage {
  name?: string
  path?: string
}

export interface IAlert {
  id?: string
  pages?: IAlertPage[]
  closable?: boolean
  title?: string
  icon?: AlertIcons
  message?: string
  type?: AlertType
  maxDate?: Date
  method?: AlertMethod
}

@Module({
  dynamic: true,
  store,
  name: 'AlertsModule',
  namespaced: true,
})
class AlertsModule extends VuexModule {
  alerts: IAlert[] = []

  get alertsByPage() {
    return (pages: IAlertPage, method: AlertMethod) => {
      return this.alerts.filter(
        alert =>
          (!alert.pages ||
            alert.pages.findIndex(
              storePage =>
                storePage.name === pages.name || storePage.path === pages.path,
            ) >= 0) &&
          alert.method === method,
      )
    }
  }

  @Mutation
  removeOldAlerts() {
    this.alerts = this.alerts.filter(
      alert => alert.maxDate >= new Date() || !alert.maxDate,
    )
  }

  @Action
  addAlert(alert: IAlert) {
    if (!alert.id) {
      alert.id = uuid()
    } else {
      this.removeAlertsById([alert.id])
    }
    this.pushAlert(alert)
  }

  @Mutation
  pushAlert(alert: IAlert) {
    this.alerts.push(alert)
  }

  @Mutation
  removeAlertsById(alertIds: string[]) {
    this.alerts = this.alerts.filter(alert => !alertIds.includes(alert.id))
  }

  @Mutation
  removeAllAlerts() {
    this.alerts = []
  }

  @Mutation
  removeAlertByPage(pages: IAlertPage[]) {
    this.alerts = this.alerts.map(alert => ({
      ...alert,
      pages: alert.pages?.filter(
        sPage =>
          !pages.some(
            page => sPage.name === page.name || sPage.path === page.path,
          ),
      ),
    }))
  }
}

export const Alert = getModule(AlertsModule)
