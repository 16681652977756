import { Component, Vue, Watch } from 'vue-property-decorator'
import { mixin as focusMixin } from 'vue-focus'
import { User } from '@/base-module/state/UserModule'
import { Flattener, IFlatArrayElement } from '@/infrastructure/helpers/code'
import { OrganisationStructureResponse } from '@/api/api.proxy'
import { RouteZoneInstellingen } from '@/instellingen-zone-module/instellingen-zone-routes'
import { RouteNotificationsInstellingen } from '@/instellingen-notifications-module/instellingen-notifications-routes'
import { GipodNotifications } from '@/infrastructure/constants/scopes'
import { ApplicationSettings } from '@/base-module/state/ApplicationSettings'

@Component({
  name: 'ProfileDropdown',
  mixins: [focusMixin],
})
export default class ProfileDropdown extends Vue {
  public isOpen: boolean = false
  public toggleFocus: boolean = false
  public selectedOrganisation: string = User.selectedOrganisation
  public organisationStructure: OrganisationStructureResponse = null

  get zoneSettingsRoute() {
    return RouteZoneInstellingen
  }

  get notificationsSettingsRoute() {
    return RouteNotificationsInstellingen
  }

  get isSettingsVisible(): boolean {
    return !User.isContractorOnly
  }

  get name(): string {
    return User && User.fullName ? User.fullName : null
  }

  get selectedOrganisationName(): string {
    return User.selectedOrganisationName
  }

  get showOrganisationsMenu() {
    return this.organisationsMap
  }

  get showOrganisationsSelect() {
    return this.organisationsMap && this.organisationsMap.length
  }

  get organisationsMap() {
    const options: IFlatArrayElement[] = []
    if (
      this.organisationStructure?.children &&
      this.organisationStructure.children.length
    ) {
      options.push({
        indentation: 0,
        value: this.organisationStructure.value,
        prefName: this.organisationStructure.prefName,
      })
      Flattener(
        options,
        this.organisationStructure.children,
        1,
        false,
        'children',
      )
    }
    return options
  }

  get hasNotificationScope() {
    return User.hasScope([GipodNotifications])
  }

  @Watch('selectedOrganisation')
  onSelectedOrganisationChange(selectedOrganisation: string) {
    User.setSelectedOrganisation(selectedOrganisation)
    this.$router.go(0)
  }

  close() {
    this.isOpen = false
  }

  toggle() {
    this.isOpen = !this.isOpen
  }

  mounted() {
    this.organisationStructure = User.organisationStructure
  }

 

  get logoutUrl(): string {    
    return  `/auth/logout` 
  }
}
