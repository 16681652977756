import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { GipodMhRead, GipodMhWrite } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteToelatingen } from '../toelatingen-routes'

export const RouteGeneralPermissionDetail: GipodRouter.GRouteConfig = {
  path: `${RouteToelatingen.path}/algemenetoelating/:gipodId`,
  name: 'algemenetoelating-detail',
  component: lazyLoadComponent(() => import('./algemenetoelating-detail.vue')),
  meta: {
    authorize: {
      scopes: [GipodMhRead, GipodMhWrite],
    },
    title: `Algemene toelating | ${RouteToelatingen.meta.title}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteGeneralPermissionDetail] as GipodRouter.GRouteConfig[]
