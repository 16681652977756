import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodSpWrite, GipodMhWrite } from '@/infrastructure/constants/scopes'
import { RouteIntakes } from '@/inname-module/inname-routes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteConfig } from 'vue-router'
import { defineAsyncComponent } from "vue";

export const RouteSignalisatievergunningsaanvraag: GipodRouter.GRouteConfig = {
  path: '/toelatingen/signalisatievergunning/nieuw/:gipodId?',
  name: 'signalisatievergunningsaanvraag',
  component: defineAsyncComponent({
    loader: () => import('./aanvraag.vue'),
    timeout: 3000,
  }),
  meta: {
    authorize: {
      scopes: [GipodSpWrite, GipodMhWrite],
    },
    redirect: () => `${RouteIntakes.alias}`,
    title: `Signalisatievergunning | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Toelatingen,
  },
}

export default [RouteSignalisatievergunningsaanvraag] as RouteConfig[]
