import { defineAsyncComponent } from "vue";
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodPdoRead } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'

export const RouteIntakes: GipodRouter.GRouteConfig = {
  path: '/inname',
  alias: '/',
  name: 'inname',
  component: defineAsyncComponent({
    loader: () => import('./inname.vue'),
    timeout: 3000,
  }),
  meta: {
    authorize: {
      scopes: [GipodPdoRead],
    },
    title: `Innames | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Intake,
  },
}

export default [RouteIntakes] as GipodRouter.GRouteConfig[]
