
const delay= (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default class ProgressTracker {
  private counter: number = 0;
  private _loaded: boolean = false;

  constructor(
    private name:string, 
    private total: number,
    private enableLogger: boolean = false) {}

  public reset(total: number): void {
    this.total = total;
    this.counter = 0;
  }

  public increment(): void {
    this.counter++;
    this._loaded = this.isComplete();
    this.log();
  }

  public isComplete(): boolean {
    return this.counter >= this.total;
  }

  get loaded(): boolean {
    return this._loaded;
  }

  private log() {
    if(!this.enableLogger) return;
    const name = this.name ?? "ProgressTracker"
    let message = `${name}: ${this.counter}/${this.total} complete...`
    if(this.isComplete()){
      message = `${name}: finished!`
    }
    console.log(message)
  }

  public async wait() {
    while(!this.loaded) {
      await delay(2);
    }
  }

  public async onComplete(callback:  () => void) {
    await this.wait();
    return callback();
  }

  public async onCompleteAsync(callback: () => Promise<void>) {
    await this.wait();
    return await callback();
  }
}