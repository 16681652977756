import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodNotifications } from '@/infrastructure/constants/scopes'

export const RouteNotificationsInstellingen: GipodRouter.GRouteConfig = {
  path: '/instellingen/notificaties-email',
  name: 'notificatie-instellingen',
  component: lazyLoadComponent(() => import('./instellingen-notifications.vue')),
  meta: {
    authorize: {
      scopes: [GipodNotifications],
    },
    title: `Instellingen | ${BaseTitle}`,
  },
}

export default [RouteNotificationsInstellingen] as GipodRouter.GRouteConfig[]
