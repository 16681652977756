import { GipodRouter } from '../gipod-router'
import { NavigationGuardNext } from 'vue-router'
import { Alert } from '@/base-module/state/Alerts'

export async function clearAlertsGuard(
  to: GipodRouter.GRoute,
  from: GipodRouter.GRoute,
  next: NavigationGuardNext,
) {
  if (to.name !== from.name) Alert.removeAlertByPage([from])
  next()
}
