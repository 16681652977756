import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/base-module/state/UserModule';
import ProfileDropdown from './components/profile-dropdown/profile-dropdown.vue';
import GipodNavigation from './components/gipod-navigation/gipod-navigation.vue';
import NotificationIcon from './components/notification-icon/notification-icon.vue';
import { RouteDetail } from '@/inname-module/detail-module/detail-module-routes';
import {
    entityTypeGroundwork,
    entityTypeWork,
    entityTypeEvent,
    entityTypeSleufsynergieAanvraag,
    entityTypeSleufsynergie,
    entityTypeMobiliteitshinder,
    entityTypeGeneralPermit,
    entityTypeSignalisatieVergunning,
    entityTypeVraagVoorGrondwerk,
    entityTypeJaarlijkseSignalisatieVergunning
} from '@/infrastructure/constants/guids';
import { HinderDetail } from '@/hinder-module/hinder-detail-module/hinder-detail-routes';
import { RouteSynergieAanvraag } from '@/samenwerkingen-module/synergieaanvraag-module/synergieaanvraag-routes';
import { RouteSynergy } from '@/samenwerkingen-module/synergie-module/synergie-routes';
import { IEnvStyling } from '@/infrastructure/helpers/ui';
import { MeldingenRoute } from '@/meldingen-module/meldingen-routes';
import { RouteRequestForGroundworkDetail } from '@/toelatingen-module/vraagvoorgrondwerken-detail-module/vraagvoorgrondwerken-detail-routes';
import { RouteGeneralPermissionDetail } from '@/toelatingen-module/algemenetoelating-detail-module/algemenetoelating-detail-routes';
import { RouteSignalisatievergunningLees } from '@/toelatingen-module/signalisatievergunning-lees-module/signalisatievergunning-lees-routes';
import { RouteJaarvergunningSignalisatieDetail } from '@/toelatingen-module/modules/jaarvergunning/jaarvergunning-detail/jaarvergunningen-detail-routes';
@Component({
    name: 'FunctionalHeader',
    components: {
        ProfileDropdown,
        GipodNavigation,
        NotificationIcon
    }
})
export default class FunctionalHeader extends Vue {
    gipodId: string = '';
    searchError: string = '';

    @Prop()
    envStyling: IEnvStyling;
    get isUpdating(): boolean {
        return User.isUpdating;
    }

    get loginUrl(): string {
        return `/auth/login?state=${window.location.pathname + window.location.search}`;
    }

    get isLoggedIn(): boolean {
        return User.isLoggedIn;
    }

    get isNotificationsVisible(): boolean {
        return User.hasScope(MeldingenRoute.meta.authorize.scopes) && !User.isContractorOnly;
    }

    get isSearchVisible(): boolean {
        return !User.isContractorOnly;
    }

    resetSearch() {
        this.searchError = '';
    }

    searchGipodId() {
        this.$client
            .utilities_QuickSearch(Number(this.gipodId))
            .then((result) => {
                if (result) {
                    if (result.deleted) {
                        this.searchError = 'Dit item is verwijderd';
                    } else {
                        this.searchError = '';
                        const id = result.gipodId.toString();
                        switch (result.entityType.value) {
                            case entityTypeGroundwork:
                            case entityTypeWork:
                            case entityTypeEvent:
                                this.$router.push({ name: RouteDetail.name, params: { id } });
                                break;
                            case entityTypeMobiliteitshinder:
                                this.$router.push({ name: HinderDetail.name, params: { id } });
                                break;
                            case entityTypeSleufsynergieAanvraag:
                                this.$router.push({
                                    name: RouteSynergieAanvraag.name,
                                    params: { id }
                                });
                                break;
                            case entityTypeSleufsynergie:
                                this.$router.push({ name: RouteSynergy.name, params: { id } });
                                break;
                            case entityTypeGeneralPermit:
                                this.$router.push({
                                    name: RouteGeneralPermissionDetail.name,
                                    params: { gipodId: id }
                                });
                                break;
                            case entityTypeSignalisatieVergunning:
                                this.$router.push({
                                    name: RouteSignalisatievergunningLees.name,
                                    params: { id }
                                });
                                break;
                            case entityTypeVraagVoorGrondwerk:
                                this.$router.push({
                                    name: RouteRequestForGroundworkDetail.name,
                                    params: { gipodId: id }
                                });
                                break;
                            case entityTypeJaarlijkseSignalisatieVergunning:
                                this.$router.push({
                                    name: RouteJaarvergunningSignalisatieDetail.name,
                                    params: { gipodId: id }
                                });
                                break;
                            default:
                                this.searchError = 'Dit is geen geldig GIPOD ID';
                                break;
                        }
                    }
                } else {
                    this.searchError = 'Dit is geen geldig GIPOD ID';
                }
            })
            .catch(() => {
                this.searchError = 'Dit is geen geldig GIPOD ID';
            });
    }
}
