import store from "../base-store";
import {
    UserInfo,
    OrganisationInfo,
    PublicDomainAdministratorResponse,
    LocalGovernmentResponse
} from "@/api/api.proxy";

import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule,
} from "vuex-module-decorators";
import { ApiProxySingleton } from "@/plugins/proxy-client";
import { IFlatArrayElement, Flattener } from "@/infrastructure/helpers/code";
import {
    GipodOrgRead,
    GipodSpRead,
    GipodSpWrite,
} from "@/infrastructure/constants/scopes";
import { ApplicationSettings } from "./ApplicationSettings";

@Module({
    dynamic: true,
    store,
    name: "UserDataModule",
    namespaced: true,
})
export class UserModule extends VuexModule {
    public static readonly namespace = "UserDataModule";

    private user: UserInfo = null;
    public isUpdating: boolean = false;
    public isAuthenticated: boolean = false;
    public isCheckingAuthentication: boolean = true;
    public organisationInfo: OrganisationInfo = null;
    public flatOrganisationStructure: IFlatArrayElement[] = [];
    public selectedOrganisation: string = null;
    public selectedOrganisationName: string = null;
    public selectedOrganisationLocalStoragKey: string = "selected-organisation";

    get isLoggedIn(): boolean {
        return this.user && !!this.user.name;
    }

    get userContext(): string {
        return this.user && this.user.name
            ? this.user.contactId.toString()
            : "";
    }

    public get fullName(): string {
        return this.user
            ? `${this.user.firstName ? this.user.firstName : ""}${this.user.firstName && this.user.lastName ? " " : ""
            }${this.user.lastName ? this.user.lastName : ""}`
            : "";
    }

    get isLocalGovernement(): string {
        if (this.organisationInfo) {
            return this.organisationInfo.niscode;
        } else {
            return null;
        }
    }

    get isDomainAdmin(): boolean {
        return (
            !!this.isLocalGovernement ||
            this.organisationInfo.childrenWithOdbCode.length > 0
        );
    }

    get hasGeneralPermitEnabled(): boolean {
        return this.organisationInfo.hasGeneralPermitEnabled;
    }

    get hasAnnualSignalingPermitEnabled(): boolean {
        return this.organisationInfo.hasAnnualSignalingPermitEnabled;
    }

    get parentOrganisation(): string {
        if (this.organisationInfo) {
            return this.organisationInfo.hierarchy.value;
        } else {
            return null;
        }
    }

    get isContractorOnly() {
        if (
            this.userInfo &&
            this.userInfo.scopes &&
            this.userInfo.scopes.length
        ) {
            return this.userInfo.scopes.every(
                (scope) =>
                    scope === GipodSpWrite ||
                    scope === GipodSpRead ||
                    scope === GipodOrgRead
            );
        }
    }

    get organisationStructure() {
        if (this.organisationInfo) {
            return this.organisationInfo.hierarchy;
        } else {
            return null;
        }
    }

    get hasScope() {
        return (scopes: string[]): boolean => {
            if (scopes.length > 0) {
                if (this.user && this.user.scopes) {
                    return this.user.scopes
                        .some((scope) => scopes.includes(scope))
                        .valueOf();
                }
                return false;
            } else {
                return true;
            }
        };
    }

    get hasGuids() {
        return (guids: string[]): boolean => {
            if (guids.length > 0) {
                if (this.user && this.flatOrganisationStructure) {
                    return this.flatOrganisationStructure
                        .some((organisation) =>
                            guids.includes(organisation.value)
                        )
                        .valueOf();
                }
                return false;
            } else {
                return true;
            }
        };
    }
    

    get userInfo(): UserInfo {
        return this.user;
    }

    get authenticated(): boolean {
        return this.isLoggedIn;
    }

    @Mutation
    public updateIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    @Mutation
    public updateIsCheckingAuthentication(isChecking: boolean) {
        this.isCheckingAuthentication = isChecking;
    }

    @Action({ rawError: true })
    public async isUserAuthenticated(): Promise<boolean> {
        this.updateIsCheckingAuthentication(true);        

        const isAuthenticated =
            await ApiProxySingleton.acmIdmAuthentication_IsAuthenticated();
        this.updateIsAuthenticated(isAuthenticated);
        return isAuthenticated;
    }

    @Action({ rawError: true })
    public async updateUserProfile() {
        this.updatingProfile(true);
        return ApiProxySingleton.acmIdmAuthentication_GetUserInfo()
            .then(async (user) => {
                this.mutationUpdateUserProfile(user);
                await this.updateOrganisationInfo(user);
                if (!user) {
                    this.updatingProfile(false);
                }
                return Promise.resolve(user);
            })
            .catch(() => {
                this.updatingProfile(false);
                return Promise.reject();
            });
    }

    @Action({ rawError: true })
    public async GetApiToken() {
        return ApiProxySingleton.acmIdmAuthentication_GetAccessTokenForDemoPage()
            .then(async (tokenResult) => {
                if (tokenResult.isSuccess)
                    return Promise.resolve(
                        `${tokenResult.response.token_type} ${tokenResult.response.access_token}`
                    );
                else return Promise.reject();
            })
            .catch(() => {
                return Promise.reject();
            });
    }

    @Action({ rawError: true })
    public async updateOrganisationInfo(user: UserInfo) {
        if (!user.organisationId) { return; }

        try {
            const ATODBS =
                await ApiProxySingleton.organisationsRead_GetAllPublicDomainAdministratorsWithGeneralPermitEnabled();
            const organisationInfo = await ApiProxySingleton.organisationsRead_GetLoggedOnOrganisationInfo();
            this.mutationUpdateOrganisationInfo(organisationInfo);
            this.getSelectedOrganisation();
            this.setHasGeneralPermitEnabled(ATODBS);

            if(this.isLocalGovernement) {
                const localGoverment = await ApiProxySingleton.organisationsRead_GetLocalGovernmentByNiscode(this.organisationInfo.niscode);
                this.setHasAnnualSignalingPermit(localGoverment);
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.updatingProfile(false);
        }
    }

    @Action({ rawError: true })
    public getSelectedOrganisation() {
        if (
            localStorage &&
            localStorage.getItem(this.selectedOrganisationLocalStoragKey) &&
            !!this.flatOrganisationStructure.find(
                (organisation) =>
                    organisation.value ===
                    localStorage.getItem(
                        this.selectedOrganisationLocalStoragKey
                    )
            )
        ) {
            const selectedOrganisationDetails =
                this.flatOrganisationStructure.find(
                    (organisation) =>
                        organisation.value ===
                        localStorage.getItem(
                            this.selectedOrganisationLocalStoragKey
                        )
                );
            this.mutationUpdateSelectedOrganisation(
                selectedOrganisationDetails.value
            );
            this.mutationUpdateSelectedOrganisationName(
                selectedOrganisationDetails.prefName
            );
        } else if (this.organisationInfo) {
            this.mutationUpdateSelectedOrganisation(
                this.organisationInfo.hierarchy.value
            );
            this.mutationUpdateSelectedOrganisationName(
                this.organisationInfo.hierarchy.prefName
            );
        } else {
            this.mutationUpdateSelectedOrganisation("");
            this.mutationUpdateSelectedOrganisationName("Org Not Found");
        }
    }

    @Action({ rawError: true })
    public setSelectedOrganisation(selectedOrganisation: string) {
        const organisationDetails = this.flatOrganisationStructure.find(
            (organisation) => organisation.value === selectedOrganisation
        );
        if (organisationDetails) {
            this.mutationUpdateSelectedOrganisation(
                selectedOrganisation.toString()
            );
            this.mutationUpdateSelectedOrganisationName(
                organisationDetails.prefName
            );
            if (localStorage) {
                localStorage.setItem(
                    this.selectedOrganisationLocalStoragKey,
                    selectedOrganisation.toString()
                );
            }
        }
    }

    @Mutation
    public updatingProfile(isUpdating: boolean) {
        this.isUpdating = isUpdating;
    }

    @Mutation
    public mutationUpdateUserProfile(user: UserInfo) {
        this.user = user;
    }

    @Mutation
    public mutationUpdateOrganisationInfo(organisationInfo: OrganisationInfo) {
        this.organisationInfo = organisationInfo;
        if (this.organisationInfo) {
            return Flattener(
                this.flatOrganisationStructure,
                [this.organisationInfo.hierarchy],
                undefined,
                undefined,
                "children"
            );
        }
    }

    @Mutation
    public mutationUpdateSelectedOrganisation(organisation: string) {
        this.selectedOrganisation = organisation;
    }

    @Mutation
    public mutationUpdateSelectedOrganisationName(organisationName: string) {
        this.selectedOrganisationName = organisationName;
    }

    @Mutation
    public setHasGeneralPermitEnabled(
        ATODBS: PublicDomainAdministratorResponse[]
    ) {
        if (this.organisationInfo) {
            this.organisationInfo.hasGeneralPermitEnabled = ATODBS.some(
                (ATODB) => ATODB.value === this.selectedOrganisation
            );
        }
    }

    @Mutation
    public setHasAnnualSignalingPermit(
        localGovermentResponse: LocalGovernmentResponse
    ) {
        if (!this.organisationInfo) {
            return;
        }
        const {
            annualSignalingPermitEnabled,
            annualSignalingPermitToolUri
        } = localGovermentResponse
        this.organisationInfo.hasAnnualSignalingPermitEnabled = annualSignalingPermitEnabled ?? false;
        this.organisationInfo.annualSignalingPermitToolUri = annualSignalingPermitToolUri ?? null;
    }
}

export const User = getModule(UserModule);
