import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'NotFound',
})
export default class NotFound extends Vue {
  get routeList() {
    return this.$router.getRoutes()
  }
}
