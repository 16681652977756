import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodNotifications } from '@/infrastructure/constants/scopes'
import { RouteConfig } from 'vue-router'

export const MeldingenRoute: GipodRouter.GRouteConfig = {
  path: '/notificaties',
  name: 'notificaties',
  component: lazyLoadComponent(() => import('./meldingen.vue')),
  meta: {
    title: `Notificaties | ${BaseTitle}`,
    authorize: {
      scopes: [GipodNotifications],
    },
  },
}

export default [MeldingenRoute] as RouteConfig[]
