import Vue from 'vue'
import { upperFirst, camelCase } from 'lodash';
import ModuleLoader from '@/plugins/helpers/module-loader'

const loader = new ModuleLoader('directives', import.meta.glob('@/directives/*.ts'))
export const directiveLoaderTask = loader;

export default {
  install(vue: typeof Vue) {
    loader.iter<any>((path, modulePromise) => {
      const name = upperFirst(
        camelCase(path.match(/([^/]+)(?=\.\w+$)/)[0])
      );
      vue.directive(name, modulePromise)
    });
  },
}