import { lazyLoadComponent } from '@/base-module/router/base-router'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import { GipodTsRead } from '@/infrastructure/constants/scopes'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'

export const RouteSamenwerkingen: GipodRouter.GRouteConfig = {
  path: '/samenwerking',
  name: 'samenwerking',
  component: lazyLoadComponent(() => import('./samenwerkingen-module.vue')),
  meta: {
    authorize: {
      scopes: [GipodTsRead],
    },
    title: `Samenwerkingen | ${BaseTitle}`,
    activeNavigation: GipodNavigationItemKey.Cooperation,
  },
}

export default [RouteSamenwerkingen] as GipodRouter.GRouteConfig[]
