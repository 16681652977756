import VueRouter, { RouteConfig } from 'vue-router'
import Vue from 'vue'
import ModuleLoader from '@/plugins/helpers/module-loader'

export enum GipodNavigationItemKey {
  Intake = 'Intake',
  Cooperation = 'Cooperation',
  Hindrance = 'Hindrance',
  DeLijn = 'DeLijn',
  Kaart = 'Kaart',
  Toelatingen = 'Toelatingen',
  Signalisatievergunningen = 'SignalisatieVergunningen',
  Jaarvergunningen = 'Jaarvergunningen',
}

const loader = new ModuleLoader('routes',import.meta.glob('@/**/*-routes.ts'))
export const routeLoaderTask = loader;

export default {
  install(vue: typeof Vue, options?: { router: VueRouter }) {
    loader.iter<RouteConfig[]>((path, routeConfigs) => {
      routeConfigs.forEach(routeConfig =>
        options.router.addRoute(routeConfig.name, routeConfig))
    }
    );
  },
}
