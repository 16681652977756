import { lazyLoadComponent } from '@/base-module/router/base-router'
import { RouteConfig } from 'vue-router'
import { RouteHinder } from '../hinder-routes'

export const HinderDetail: RouteConfig = {
  path: `${RouteHinder.path}/:id`,
  name: 'hinder-detail',
  component: lazyLoadComponent(() => import('./hinder-detail.vue')),
}

export default [HinderDetail] as RouteConfig[]
