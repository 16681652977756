import { GipodRouter } from '@/base-module/router/gipod-router'
import { BaseTitle } from '@/infrastructure/constants/route-titles'
import {
  GipodOrgRead,
  GipodOrgSettings,
  GipodOrgWrite,
} from '@/infrastructure/constants/scopes'
import { defineAsyncComponent } from "vue";

export const RouteZoneInstellingen: GipodRouter.GRouteConfig = {
  path: '/instellingen/samenwerkingszone',
  name: 'zone-instellingen',
  component: defineAsyncComponent({
    loader: ()=> import('./instellingen-zone.vue'),
    timeout: 3000,
  }),
  meta: {
    authorize: {
      scopes: [GipodOrgSettings, GipodOrgWrite, GipodOrgRead],
    },
    title: `Instellingen | ${BaseTitle}`,
  },
}

export default [RouteZoneInstellingen] as GipodRouter.GRouteConfig[]
