import { lazyLoadComponent } from '@/base-module/router/base-router'
import { RouteIntakes } from '../inname-routes'
import { GipodPdoRead } from '@/infrastructure/constants/scopes'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'

export const RouteDetail: GipodRouter.GRouteConfig = {
  path: `${RouteIntakes.path}/:id`,
  name: 'intake-detail',
  component: lazyLoadComponent(() => import('./detail-module.vue')),
  meta: {
    authorize: {
      scopes: [GipodPdoRead],
    },
    dynamicTitle: route => `${route.params.id} | ${RouteIntakes.meta.title}`,
    activeNavigation: GipodNavigationItemKey.Intake,
  },
}

export default [RouteDetail] as GipodRouter.GRouteConfig[]
