import ProgressTracker from '@/plugins/helpers/progress-tracker'

const delay= (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default class ModuleLoader {
  private _modules: { path: string, modulePromise: () => Promise<unknown> }[] = [];
  private _progress: ProgressTracker

  constructor(
    private name: string,
    private glob: Record<string, () => Promise<unknown>>,
    private debug: boolean = false) {
  }

  private load() {
    this._modules = [];
    const entries = Object.entries(this.glob);
    for (let i = 0; i < entries.length; i++) {
      const [path, modulePromise] = entries[i]
      this._modules.push({ path, modulePromise })
    }
  }

  public iter<T>(itemFunc :(path: string, module: T) => void): ModuleLoader {
    this.load()
    const count = this._modules.length - 1;
    this._progress = new ProgressTracker(this.name, count, this.debug);
    for (let i = 0; i < this._modules.length; i++) {
      const { path, modulePromise } = this._modules[i];
      if(!path.startsWith("/src/")) { continue; }
      modulePromise()
      .then(module => {
        const m = (module as any).default || (module as any) as T;
        itemFunc(path, m)
        this._progress.increment();
      })
    }

    return this
  }

  public async wait() {
    while(this._progress == undefined) {
      await delay(2)
    }
    await this._progress.wait();
  }
}
